<template>
    <div class="main-layout">
        <div class="bg-wrapper">
            <div class="upper-gradient"></div>
            <!-- <div class="lower-gradient"></div> -->
        </div>

        <div class="content">
            <a href="https://eurojackpot.nederlandseloterij.nl" @click="trackEvent" target="_blank"><img class="ejp-logo" src="/static/img/ejp-logo.png" /></a>
            <slot></slot>
        </div>

        <div class="footer">
            <div class="grid__column">
                <div class="footer__links">
                    <a href="https://www.nederlandseloterij.nl/privacy" target="_blank" rel="noopener" class="footer__link">Privacy</a>
                    <a href="https://www.nederlandseloterij.nl/speel-bewust" target="_blank" rel="noopener" class="footer__link">Speel bewust</a>
                    <a href="https://www.nederlandseloterij.nl/gedragscodes" target="_blank" rel="noopener" class="footer__link">Gedragscode</a>
                    <a href="https://www.nederlandseloterij.nl/deelnemersreglementen" target="_blank" rel="noopener" class="footer__link">Deelnemersreglement</a>
                    <a href="https://www.nederlandseloterij.nl/trekkingsprocedures/" target="_blank" rel="noopener" class="footer__link">Trekkingsprocedures</a>
                    <a href="https://www.nederlandseloterij.nl/disclaimer" target="_blank" rel="noopener" class="footer__link">Disclaimer</a>
                    <a href="https://www.nederlandseloterij.nl/cookies" target="_blank" rel="noopener" class="footer__link">Cookies</a>
                    <a href="https://www.nederlandseloterij.nl/vergunningen" target="_blank" rel="noopener" class="footer__link">Vergunning</a>
                    <a href="https://www.nederlandseloterij.nl/responsible-disclosure" target="_blank" rel="noopener" class="footer__link">Responsible Disclosure</a>
                    <a href="https://www.nederlandseloterij.nl/klantenservice/eurojackpot" target="_blank" rel="noopener" class="footer__link">Klantenservice</a>
                    <a href="https://eurojackpot.nederlandseloterij.nl/app" class="footer__link">App download</a>
                    <a href="https://eurojackpot.nederlandseloterij.nl/actievoorwaarden" class="footer__link">Actievoorwaarden</a>
                    <a href="https://www.nederlandseloterij.nl/magazine" target="_blank" rel="noopener" class="footer__link">Magazine</a>
                </div>
                <p class="footer__descriptive">
                    <span>Voor het organiseren van Eurojackpot is aan Lotto B.V. vergunning verleend door de Kansspelautoriteit, kenmerk 10509 d.d. 23 december 2016</span> <br> <span>Copyright© Nederlandse Loterij - Alle rechten voorbehouden</span>
                </p>
                <div class="footer__nl">
                    <a href="https://www.nederlandseloterij.nl/">
                        <img data-src="https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl.png?v=n5YJRr2XaJjOQckZLRT-s_5_EoeACVqB9HoBxH05YAs" data-srcset="https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl.png?v=n5YJRr2XaJjOQckZLRT-s_5_EoeACVqB9HoBxH05YAs 1x, https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl@2x.png?v=zrFnGyYouVWde4zeSOuOvadX51ZE1J-l6Z01IRleLbE 2x,https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl@3x.png?v=dfxVbqV8kpn905nJkVDmESuCIINJYzl3gjesoQHSapI 3x" alt="Een spel van de Nederlandse Loterij" class="lazy-load" src="https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl.png?v=n5YJRr2XaJjOQckZLRT-s_5_EoeACVqB9HoBxH05YAs" srcset="https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl.png?v=n5YJRr2XaJjOQckZLRT-s_5_EoeACVqB9HoBxH05YAs 1x, https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl@2x.png?v=zrFnGyYouVWde4zeSOuOvadX51ZE1J-l6Z01IRleLbE 2x,https://eurojackpot-cdn.nederlandseloterij.nl/images/logo-nl@3x.png?v=dfxVbqV8kpn905nJkVDmESuCIINJYzl3gjesoQHSapI 3x"></a> <a href="https://www.nederlandseloterij.nl/speel-bewust" rel="noopener" target="_blank" class="footer__aware-link">
                        Speel Bewust 18+
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.footer{
    position:relative;
    background-color:#f5f5f5;
    font-family: Roboto;
    text-transform:none;
    font-size:.8125rem;

    .footer__links{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        padding:1rem;
        width:800px;
        max-width:100%;
        margin:0 auto;

        a{
            padding:0.5rem 1rem;
            color:black;
            text-decoration:none;

            &:hover{
                text-decoration:underline;
            }
        }
    }

    .footer__descriptive{
        color:black;
        span{
            font-size:.8125rem;
        }
    }

    .footer__nl{
        padding-bottom:2rem;
        background-color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-transform:uppercase;

        a{
            color:#787f87;
            text-decoration: none;
        }
    }
}
</style>

<script>
    export default {
        methods: {
            trackEvent()
            {
                window.dataLayer.push({
                    event: 'clickOut',
                    click_url: 'https://eurojackpot.nederlandseloterij.nl/'
                });
            }
        }
    };
</script>
