import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            }
        },
        {
            path: '/gegevens',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            }
        },
        {
            path: '/bedankt',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/app/pages/Thanks' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    let trackingAlias = {
        '/': '/landing',
        '/game': '/game',
        '/gegevens': '/gegevens',
        '/bedankt': '/bedankt'
    };

    window.dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    if( to.fullPath === '/bedankt' )
    {
        let hasKit = store.getters['entry/get'].kit;
        window.dataLayer.push({
            event: 'confirmation',
            newsletter_optin: hasKit,
            nyx_id: ''
        });
    }

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
